// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons';


.form-control:focus {
    box-shadow: none;
    border: 1px solid #ced4da;
}

.btn:focus{
    box-shadow: none !important;
}

